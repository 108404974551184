import { useEffect, useState, useMemo } from "react"
import { RULES } from '@ais/constants';
import { useProjectFormContext } from "@contexts";
import { useFormContext, useWatch } from "react-hook-form"
const useRules = (rules) => {

    const [shouldDisplay, setShouldDisplay] = useState(true)

    const { projectForm } = useProjectFormContext()
    const { getValues, control } = useFormContext()
    const watchedSection = useWatch({ control: control, name: rules?.criterias?.map(c => c.questionId) ?? [] });
    const methodologyIndustries = useMemo(() => {
        let mi = projectForm.methodologyIndustries
        if (!mi) {
            mi = []
        }
        mi = mi.map((industry) => industry.MethodologyIndustryId)
        return mi
    }, [projectForm])

    const auditAreas = useMemo(() => {
        let aa = projectForm.auditAreas
        if (!aa) {
            aa = []
        }
        aa = aa.map((aa) => aa.AuditAreaId)
        return aa
    }, [projectForm])

    const scotabds = useMemo(() => {
        let s = projectForm.scotabds
        if (!s) {
            s = []
        }
        s = s.map((s) => s.SCOTABDId)
        return s
    }, [projectForm])

    useEffect(() => {
        const criterias = rules?.criterias;
        const results = criterias?.reduce(evaluateCriteria, []);
        let rulesMatching = false;
        if (results?.length) {
            const isBehaviorShow = rules?.behavior === RULES.BEHAVIOR.SHOW;
            if (rules?.match === RULES.MATCH.ANY) {
                rulesMatching = results.some((result) => result === true);
            } else if (rules?.match === RULES.MATCH.ALL) {
                rulesMatching = results.every((result) => result === true);
            }
            setShouldDisplay(isBehaviorShow ? rulesMatching : !rulesMatching);
        } else {
            setShouldDisplay(true);
        }

    }, [rules, methodologyIndustries, watchedSection, getValues, auditAreas, scotabds])

    const evaluateCriteria = (result, criteria) => {
        const { criteriaType, questionId, any, isEqual, value } = criteria;
        switch (criteriaType) {
            case RULES.CRITERIA_TYPES.QUESTION:
                const formikValue = getValues(questionId);
                const answerValue = Array.isArray(formikValue)
                    ? formikValue.filter(
                        (value) => value !== undefined && value !== ''
                    )
                    : [formikValue].filter(
                        (value) => value !== undefined && value !== ''
                    );
                if (any) {
                    result.push(!!answerValue)
                } else {
                    switch (isEqual) {
                        case RULES.EQUALITY.IS:
                            result.push(answerValue?.length === value?.length && answerValue.every((_formValue) => value.includes(_formValue)));
                            break;
                        case RULES.EQUALITY.IS_NOT:
                            result.push(!answerValue.every((_formValue) => value.includes(_formValue)));
                            break;
                        case RULES.EQUALITY.ANY:
                            result.push(answerValue.some((_formValue) => value.includes(_formValue)));
                            break;
                    }
                }
                break;
            case RULES.CRITERIA_TYPES.INDUSTRY:
                switch (isEqual) {
                    case RULES.EQUALITY.IS:
                        result.push(value?.every(value => methodologyIndustries?.some(industry => industry === value)));
                        break;
                    case RULES.EQUALITY.IS_NOT:
                        result.push(!methodologyIndustries?.some((industry) => value?.includes(industry)));
                        break;
                    case RULES.EQUALITY.ANY:
                        result.push(methodologyIndustries?.some((industry) => value?.includes(industry)));
                        break;
                }
                break;
            case RULES.CRITERIA_TYPES.AUDIT_AREA:
                switch (isEqual) {
                    case RULES.EQUALITY.IS:
                        result.push(value?.every(value => auditAreas?.some(auditArea => auditArea === value)));
                        break;
                    case RULES.EQUALITY.IS_NOT:
                        result.push(!auditAreas?.some((auditArea) => value?.includes(auditArea)));
                        break;
                    case RULES.EQUALITY.ANY:
                        result.push(auditAreas?.some((auditArea) => value?.includes(auditArea)));
                        break;
                }
                break;
                case RULES.CRITERIA_TYPES.SCOTABDS:
                    switch (isEqual) {
                        case RULES.EQUALITY.IS:
                            result.push(value?.every(value => scotabds?.some(scotabd => scotabd === value)));
                            break;
                        case RULES.EQUALITY.IS_NOT:
                            result.push(!scotabds?.some((scotabd) => value?.includes(scotabd)));
                            break;
                        case RULES.EQUALITY.ANY:
                            result.push(scotabds?.some((scotabd) => value?.includes(scotabd)));
                            break;
                    }
                    break;
            // This was previously commented out for the ext portal rules checking.
            // case RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK:
            //     switch (isEqual) {
            //         case RULES.EQUALITY.IS:
            //             result.push(value?.every((f) => frameworks?.includes(f)));
            //             break;
            //         case RULES.EQUALITY.IS_NOT:
            //             result.push(!frameworks?.some((f) => value?.includes(f)));
            //             break;
            //         case RULES.EQUALITY.ANY:
            //             result.push(frameworks?.some((f) => value?.includes(f)));
            //             break;
            //     }
            //     break;
            // case RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS:
            //     switch (isEqual) {
            //         case RULES.EQUALITY.IS:
            //             result.push(value?.every((ps) => performanceStandards?.includes(ps)));
            //             break;
            //         case RULES.EQUALITY.IS_NOT:
            //             result.push(!performanceStandards?.some((ps) => value?.includes(ps)));
            //             break;
            //         case RULES.EQUALITY.ANY:
            //             result.push(performanceStandards?.some((ps) => value?.includes(ps)));
            //             break;
            //     }
            //     break;                
        }
        return result;
    }

    return { shouldDisplay };
}

export default useRules